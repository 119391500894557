// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apie-cornhole-js": () => import("./../../../src/pages/apie-cornhole.js" /* webpackChunkName: "component---src-pages-apie-cornhole-js" */),
  "component---src-pages-apie-mus-js": () => import("./../../../src/pages/apie-mus.js" /* webpackChunkName: "component---src-pages-apie-mus-js" */),
  "component---src-pages-cornhole-nuoma-js": () => import("./../../../src/pages/cornhole-nuoma.js" /* webpackChunkName: "component---src-pages-cornhole-nuoma-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kainos-js": () => import("./../../../src/pages/kainos.js" /* webpackChunkName: "component---src-pages-kainos-js" */),
  "component---src-pages-kontaktai-js": () => import("./../../../src/pages/kontaktai.js" /* webpackChunkName: "component---src-pages-kontaktai-js" */),
  "component---src-pages-taisykles-js": () => import("./../../../src/pages/taisykles.js" /* webpackChunkName: "component---src-pages-taisykles-js" */),
  "component---src-pages-turnyras-js": () => import("./../../../src/pages/turnyras.js" /* webpackChunkName: "component---src-pages-turnyras-js" */)
}

